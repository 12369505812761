
































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsContributionsScheduledContributionsViewModel
  from '@/vue-app/view-models/components/contract-savings/contract-savings-contributions-scheduled-contributions-view-model';

@Component({
  name: 'ContractSavingsContributionsScheduleContributions',
  components: {
    ContractSavingsContributionsRecurringContributions: () => import('./contributions/ContractSavingsContributionsRecurringContributions.vue'),
  },
})
export default class ContractSavingsContributionsScheduleContributions extends Vue {
  scheduling_contributions_view_model = Vue.observable(
    new ContractSavingsContributionsScheduledContributionsViewModel(),
  );

  @Watch('scheduling_contributions_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  prevStep() {
    if (this.scheduling_contributions_view_model.internal_step === 2) {
      this.scheduling_contributions_view_model.internal_step = 1;
    } else {
      this.$emit('prevStep');
    }
  }

  nextStep() {
    if (this.scheduling_contributions_view_model.internal_step === 1) {
      const save_step = this.scheduling_contributions_view_model.saveStep();
      if (save_step && this.scheduling_contributions_view_model.recurring_contributions === 'yes') {
        this.scheduling_contributions_view_model.internal_step = 2;
      } else if (save_step) {
        this.$emit('nextStep');
      }
    } else {
      this.$emit('nextStep');
    }
  }

  async created() {
    this.scheduling_contributions_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.scheduling_contributions_view_model.initialize();
  }
}

