import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { v4 as uuid } from 'uuid';
import { requiredRule } from '@/vue-app/utils/form-rules';

// Application
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsContributionsScheduledContributionsViewModel {
  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly search_on_boarding_steps_query!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly search_investment_providers_query!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly create_on_boarding_step_command!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly update_on_boarding_step_command!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.contract-savings.contributions.schedule-contributions';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  investment_provider_name = 'allianz';

  exists_step = false;

  step_name = 'schedule_recurring_contributions';

  investment_provider_id = '';

  is_loading = true;

  small_screen: null | boolean = null;

  initial_contribution = '50000';

  form_validity = false;

  internal_step = 1;

  recurring_contributions = 'yes';

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {},
  };

  rules = {
    initial_contribution: [
      requiredRule,
      (value: string) => (
        parseFloat(value.replace(/[^0-9]/g, '')) >= 50000
        || i18n.tc(
          'utils.form-rules.minimum_error',
          0,
          { value: '$500.00 MXN' },
        ).toString()
      ),
    ],
  };

  get user_name() {
    return this.get_person_query.execute().name;
  }

  get investment_providers() {
    return this.search_investment_providers_query.execute();
  }

  get steps() {
    return this.search_on_boarding_steps_query.execute(this.investment_provider_id);
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      this.setInputsDataFromStep();
    }
  };

  setInputsDataFromStep = () => {
    this.recurring_contributions = this.on_boarding_step.payload.schedule_recurring_contributions ? 'yes' : 'no';
  };

  async saveStep() {
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload.schedule_recurring_contributions = this.recurring_contributions === 'yes';
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.update_on_boarding_step_command.execute(this.on_boarding_step);
      } else {
        await this.create_on_boarding_step_command.execute(this.on_boarding_step);
      }
    } catch (error) {
      this.message_notifier.showErrorNotification(this.error_message);
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.investment_providers;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    await this.verifyStep(steps);

    this.is_loading = false;
  }
}
